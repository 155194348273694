<template>
  <el-row :gutter="20" class="wrapper">
    <el-col :span="8">
      <el-form-item label="通知对象">
        <el-cascader
          :options="departmentData"
          :props="props"
          @change="depChange"
          clearable
          collapse-tags
          v-model="depData"
        ></el-cascader>
      </el-form-item>
    </el-col>
    <el-col :span="16">
      <el-form-item label="可见员工">
        <!-- <el-divider content-position="left">可见员工</el-divider> -->
        <!-- <el-tag :key="tag.name" @close="removeUid(tag)" closable v-for="tag in empData">{{tag.name}}</el-tag> -->
        <span v-if="!empData">全部</span>
        <el-tag :key="tag.name" v-for="tag in empData">{{tag.name}}</el-tag>
      </el-form-item>
    </el-col>
  </el-row>
</template>
<script>
export default {
  data() {
    return {
      visible: true,
      props: {
        multiple: true,
        value: 'pid',
        label: 'name'
      },
      departmentData: [],
      depData: [],
      empData: [],
      empArr: []
    }
  },
  mounted() {
    this.getDepartmentData()
  },
  methods: {
    show() {
      this.visible = true
    },
    //获取部门列表
    getDepartmentData() {
      this.$axios.post('/dept/lists').then(res => {
        if (res.data.code == 200) {
          this.departmentData = res.data.data
        }
      })
    },
    //选中部门
    depChange() {
      let _this = this
      _this.empData = []
      this.depData.map(item => {
        this.$axios
          .post('/muser/lists', {
            dept_id: item[item.length - 1],
            count: 10000
          })
          .then(res => {
            if (res.data.code == 200) {
              //更新用户列表
              res.data.data.list.map(info => {
                _this.empData.push({
                  name: info.realname,
                  uid: info.uid
                })

                _this.empArr.push(info.uid)
              })
            }
          })
      })
    },
    removeUid(tag) {
      this.empData.splice(this.empData.indexOf(tag), 1)
    }
  }
}
</script>
<style lang="less" scoped>
.wrapper {
  /deep/ .el-cascader {
    width: 100%;
  }
  /deep/ .el-tag {
    margin-right: 5px;
  }
}
</style>