<template>
  <div class="main" data-title="公告列表列表" v-title>
    <div class="page-header">
      <div class="page-header-body">
        <h2 class="page-header-title">公告列表</h2>
      </div>
    </div>
    <el-card class="main-content" shadow="hover">
      <div class="jue-card-header">
        <div class="jue-card-title">编辑公告</div>
        <div class="jue-card-btns"></div>
      </div>

      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        class="form"
        label-position="right"
        label-width="120px"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="标题" prop="title">
              <el-input placeholder="请输入" suffix-icon="el-icon-edit" v-model="form.title"></el-input>
            </el-form-item>
            <el-form-item label="内容" prop="content">
              <quill-editor
                :options="base.editorOption"
                @ready="onEditorReady($event)"
                class="editer"
                ref="myQuillEditor"
                v-model="form.content"
              ></quill-editor>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="过期时间">
              <el-date-picker
                @change="dateChange"
                placeholder="选择日期"
                type="date"
                v-model="form.tmp_expired_date"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="生效">
              <el-switch :active-value="1" :inactive-value="2" v-model="form.status"></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <select-dep ref="selectDep"></select-dep>
        <div class="form-footer">
          <el-button @click="submitForm('form')" type="primary">提交</el-button>
          <el-button @click="$router.push({ name: 'news-list' })">取消</el-button>
        </div>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import SelectDep from '@/components/SelectDep.vue'
export default {
  components: {
    quillEditor,
    SelectDep
  },
  data() {
    return {
      base: {
        batchBtn: false,
        editorOption: {
          modules: {
            toolbar: [
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'code-block'],
              [{ header: 1 }, { header: 2 }],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ script: 'sub' }, { script: 'super' }],
              [{ indent: '-1' }, { indent: '+1' }],
              // [{ direction: 'rtl' }],
              // [{ size: ['small', false, 'large', 'huge'] }],
              // [{ header: [1, 2, 3, 4, 5, 6, false] }],
              // [{ font: [] }],
              [{ color: [] }, { background: [] }],
              [{ align: [] }],
              ['clean'],
              ['link', 'image']
            ]
          },
          placeholder: '请输入'
        }
      },
      rules: {
        title: [{ required: true, message: '必需填写', trigger: 'blur' }],
        content: [{ required: true, message: '必需填写', trigger: 'blur' }]
      },
      type: 'add', //add | edit
      form: {
        title: '',
        content: '',
        status: '1',
        expired_date: '0000-00-00',
        tmp_expired_date: '',
        send_uids: '',
        other: ''
      }
    }
  },
  mounted() {
    if (this.$route.query.news_id) {
      this.type = 'edit'
      this.getEditData(this.$route.query.news_id)
    }
  },
  methods: {
    getEditData(news_id) {
      this.$axios
        .post('/news/detail', {
          news_id: this.$route.query.news_id
        })
        .then(res => {
          this.form.news_id = res.data.data.news_id
          this.form.title = res.data.data.title
          this.form.content = res.data.data.content
          this.form.expired_date = res.data.data.expired_date
          if (res.data.data.expired_date == '0000-00-00') {
            this.form.tmp_expired_date = ''
          } else {
            this.form.tmp_expired_date = res.data.data.expired_date
          }

          this.form.status = res.data.data.status
          this.form.other = res.data.data.other

          this.$refs.selectDep.empArr = res.data.data.send_uids.split('|')
          this.$refs.selectDep.depData = JSON.parse(res.data.data.other)
          this.$refs.selectDep.depChange()
        })
    },

    dateChange() {
      if (
        this.form.tmp_expired_date == '' ||
        this.form.tmp_expired_date == null
      ) {
        this.form.expired_date = '0000-00-00'
      } else {
        this.form.expired_date = this.form.tmp_expired_date
      }
    },
    onEditorReady(editor) {},
    submitForm(formName) {
      this.form.send_uids = this.$refs.selectDep.empArr.join('|')
      this.form.other = JSON.stringify(this.$refs.selectDep.depData)

      let url = this.type == 'add' ? '/news/create' : '/news/edit'

      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$axios.post(url, this.form).then(res => {
            if (res.data.code == 200) {
              this.$message.success(res.data.msg)
              if (this.type == 'add') {
                this.$router.push({
                  name: 'news-view',
                  query: { news_id: res.data.data.news_id }
                })
              } else {
                this.$router.push({
                  name: 'news-view',
                  query: { news_id: this.$route.query.news_id }
                })
              }
            } else {
              this.$message.error(res.data.msg)
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.form-footer {
  text-align: right;
  padding: 0 40px 0 0;
  el-button {
    margin-left: 20px;
  }
}
</style>
<style lang="less">
.ql-toolbar.ql-snow {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: 1px solid #dcdfe6;
}
.ql-container.ql-snow {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid #dcdfe6;
}
.ql-editor {
  min-height: 450px;
}
.ql-formats {
  display: flex;
  align-items: center;
}
/deep/ .el-date-editor {
  width: 100%;
}
</style>